import PrimeVue from "primevue/config";
import Aura from "@primevue/themes/aura";
import { definePreset } from "@primevue/themes";
import DefaultTheme from "../../Layout/src/assets/default-theme";

import { createApp } from "vue";
import { createPinia } from "pinia";
import { vue3Debounce } from "vue-debounce";
import { i18n } from "./i18n";

import ToastService from "primevue/toastservice";
import PrimeButton from "primevue/button";
import Toast from "primevue/toast";
import Tooltip from "primevue/tooltip";
import Checkbox from "primevue/checkbox";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import MultiSelect from "primevue/multiselect";
import InputText from "primevue/inputtext";
import ProgressSpinner from "primevue/progressspinner";
import Popover from "primevue/popover";
import InputNumber from "primevue/inputnumber";
import Dialog from "primevue/dialog";
import ConfirmationService from "primevue/confirmationservice";
import ConfirmPopup from "primevue/confirmpopup";
import TieredMenu from "primevue/tieredmenu";
import Menu from "primevue/menu";
import Card from "primevue/card";
import Select from "primevue/select";

import { FloatLabelInput } from "@cumulus/components";
import { FloatLabelDropdownPanel } from "@cumulus/components";
import { FloatLabelDropdown } from "@cumulus/components";

import clickOutsideDirective from "./discount/utils/directives";

const MyPreset = definePreset(Aura, { DefaultTheme });

export default function configureApp(app: ReturnType<typeof createApp>) {
  const pinia = createPinia();

  app.use(PrimeVue, {
    theme: {
      preset: MyPreset,
      inputVariant: "outlined", //"filled"
      options: {
        //prefix: "m",
        darkModeSelector: ".dark",
        cssLayer: {
          name: "primevue",
          order: "tailwind-base, primevue, tailwind-utilities",
        },
      },
    },
  });

  app.use(ToastService);
  app.use(i18n);
  app.use(ConfirmationService);
  app.use(pinia);

  app.component("PrimeButton", PrimeButton);
  app.component("Toast", Toast);
  app.component("Checkbox", Checkbox);
  app.component("Column", Column);
  app.component("MultiSelect", MultiSelect);
  app.component("DataTable", DataTable);
  app.component("InputText", InputText);
  app.component("ProgressSpinner", ProgressSpinner);
  app.component("Popover", Popover);
  app.component("InputNumber", InputNumber);
  app.component("PrimeDialog", Dialog);
  app.component("ConfirmPopup", ConfirmPopup);
  app.component("TieredMenu", TieredMenu);
  app.component("Card", Card);
  app.component("Select", Select);

  app.component("FloatLabelInput", FloatLabelInput);
  app.component("FloatLabelDropdownPanel", FloatLabelDropdownPanel);
  app.component("FloatLabelDropdown", FloatLabelDropdown);

  app.component("PrimeMenu", Menu);
  app.directive("click-outside", clickOutsideDirective);
  app.directive("tooltip", Tooltip);
  app.directive(
    "debounce",
    vue3Debounce({
      listenTo: "input",
    })
  );
}
