<template>
  <BeforeUnloadBrowserTab :hasUpdates="hasUpdates" />
  <div class="c-discount-margin">
    <DiscountHeader
      :isSaving="isSaving"
      :isEditing="true"
      :isDeleting="isDeleting"
      :hasUpdates="hasUpdates"
      :unsavedChangesDialogVisible="unsavedChangesDialogVisible"
      @saveClicked="onUpdateDiscount"
      @cancelClicked="onCancel"
      @deleteClicked="onDeleteDiscount"
      @dialogCancelBtnClicked="stayOnPage"
      @dialogDiscardBtnClicked="routeToSearchPage"
    />

    <div class="c-overlay" v-if="loading">
      <div class="c-spinner-container">
        <ProgressSpinner />
      </div>
    </div>
    <div class="c-content mb-6">
      <div class="c-content-top">
        <div class="c-content-top-left">
          <Card class="c-card-font mr-4">
            <template #content>
              <div class="grid grid-cols-2 gap-4 pb-2">
                <div>
                  <Name v-model:name="discount.name" :focusInput="setFocusDiscountName" />
                </div>
                <div>
                  <Description v-model:description="discount.description" />
                </div>
                <div>
                  <StatusCheckbox id="discount-active" :label="t(`discount.active`)" v-model:value="discount.active" />
                </div>
                <div>
                  <div class="grid grid-cols-3 gap-4 pb-3">
                    <StatusCheckbox
                      :label="t(`discount.active-client`)"
                      id="discount-active-for-client"
                      v-model:value="discount.activeForClient"
                    />
                    <SelectClient v-model:clientId="discount.clientId" />
                  </div>
                </div>
              </div>
            </template>
          </Card>
        </div>

        <div class="c-content-top-right">
          <Card class="c-card-font mb-4">
            <template #content>
              <div class="grid grid-cols-2 gap-4 pb-3">
                <div>
                  <SelectDiscountType v-model:type="discount.discountType" />
                </div>
                <div>
                  <SelectDiscountRule
                    v-model:rule="discount.discountRule"
                    @discountRuleChanged="updateDiscountRuleForAllProducts"
                  />
                </div>
                <div>
                  <ActiveFrom v-model:activeFrom="discount.activeFrom" />
                </div>
                <div>
                  <ActiveTo v-model:activeTo="discount.activeTo" :activeFrom="discount.activeFrom" />
                </div>
              </div>
            </template>
          </Card>
        </div>
      </div>
    </div>

    <div>
      <div class="col-span-12">
        <Card
          class="mb-4"
          :pt="{
            title: 'font-bold mb-3',
          }"
        >
          <template #title> {{ t("discount.product.label") }} </template>
          <template #content>
            <div class="col-span-12 lg:col-span-6 md:flex mb-4">
              <div class="col-span-12 md:col-span-3">
                <StatusCheckbox
                  id="discount-active-for-all-products"
                  :label="t(`discount.active-all-products`)"
                  :disabled="true"
                  v-model:value="discount.activeForAllProducts"
                />
              </div>
              <div class="col-span-12 md:col-span-4">
                <ProductSearch @productSelected="addProductToDiscountList" :discountRule="discount.discountRule" />
              </div>
            </div>
            <div class="col-span-12">
              <ProductList
                :products="selectedProducts"
                :discountRule="discount.discountRule"
                :loading="loading"
                @removeProductClicked="removeProductFromDiscount"
                @fixedPriceUpdated="updateFixedPriceOnProduct"
                @discountPercentageUpdated="updateDiscountPercentageOnProduct"
              />
            </div>
          </template>
        </Card>
      </div>
      <div class="col-span-12">
        <Card
          class="mb-4"
          :pt="{
            title: 'font-bold mb-3',
          }"
        >
          <template #title> {{ t("discount.manufacturer-group-level.label") }} </template>
          <template #content>
            <div class="col-span-12">
              <ManufacturerGroupLevelDiscount
                v-model:manufacturerGroupLevelDiscounts="discount.manufacturerGroupLevels"
                :existingManufacturerGroupLevelInfos="selectedManufacturerGroupLevels"
                :loading="loading"
              />
            </div>
          </template>
        </Card>
      </div>
      <div class="col-span-12">
        <Card
          class="mb-4"
          :pt="{
            title: 'font-bold mb-3',
          }"
        >
          <template #title> {{ t("discount.customer.label") }} </template>
          <template #content>
            <div class="grid grid-cols-12 gap-4">
              <div class="col-span-12 lg:col-span-7 c-border-div">
                <div class="col-span-12 md:col-span-10 md:flex mb-4">
                  <div class="col-span-12 md:col-span-4">
                    <StatusCheckbox
                      id="discount-active-for-all-customers"
                      :label="t(`discount.active-all-customers`)"
                      v-model:value="discount.activeForAllCustomers"
                    />
                  </div>
                  <div class="col-span-12 md:col-span-6">
                    <CustomerSearch
                      :activeForAllCustomers="discount.activeForAllCustomers"
                      @customerSelected="addCustomerToDiscount"
                    />
                  </div>
                </div>
                <div class="col-span-12">
                  <CustomerList
                    :customers="selectedCustomers"
                    :loading="loading"
                    @removeCustomerClicked="removeCustomerFromDiscount"
                  />
                </div>
              </div>
              <div class="col-span-12 lg:col-span-5">
                <div class="col-span-12">
                  <div class="col-span-12 md:col-span-4 lg:col-span-6">
                    <CustomerGroupSearch @customerGroupSelected="addCustomerGroupToDiscount" />
                  </div>
                </div>
                <div class="col-span-12 mt-1">
                  <CustomerGroupList
                    :customerGroups="selectedCustomerGroups"
                    :loading="loading"
                    @removeCustomerGroupClicked="removeCustomerGroupFromDiscount"
                  />
                </div>
              </div>
            </div>
          </template>
        </Card>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, onBeforeUnmount, onMounted, ref } from "vue";
import { useDiscountService } from "../api/price/DiscountService";
import useValidate from "@vuelidate/core";
import { onBeforeRouteLeave, useRoute, useRouter } from "vue-router";
import { useCumulusToast } from "@cumulus/toast";
import { BeforeUnloadBrowserTab } from "@cumulus/components";
import { useToast } from "primevue/usetoast";
import { ProductWithDiscountInfo } from "../model/discount/ProductWithDiscountInfo";
import { useI18n } from "vue-i18n";
import { Discount } from "../model/discount/Discount";
import { DiscountProduct } from "../model/discount/DiscountProduct";

import Name from "../components/Name.vue";
import Description from "../components/Description.vue";
import SelectDiscountType from "../components/SelectDiscountType.vue";
import SelectDiscountRule from "../components/SelectDiscountRule.vue";
import SelectClient from "../components/SelectClient.vue";
import ActiveFrom from "../components/ActiveFrom.vue";
import ActiveTo from "../components/ActiveTo.vue";
import DiscountHeader from "../components/DiscountHeader.vue";
import ProductSearch from "../components/ProductSearch.vue";
import ProductList from "../components/ProductList.vue";
import { DiscountRule } from "../model/discount/DiscountRule";
import { DiscountRoutes } from "../routes";
import CustomerSearch from "../components/CustomerSearch.vue";
import { SearchCustomer } from "../model/search/customer/SearchCustomer";
import CustomerList from "../components/CustomerList.vue";
import StatusCheckbox from "../components/StatusCheckbox.vue";
import { useDuplicatedDataService } from "../api/price/DuplicatedDataService";
import { Product } from "../model/product/Product";
import { CustomerGroup } from "../model/customer-group/CustomerGroup";
import CustomerGroupSearch from "../components/CustomerGroupSearch.vue";
import CustomerGroupList from "../components/CustomerGroupList.vue";
import ManufacturerGroupLevelDiscount from "../components/manufacturer-group-level/ManufacturerGroupLevelDiscount.vue";
import isEqual from "lodash.isequal";
import cloneDeep from "lodash.clonedeep";
import { discountProductFunctions } from "../utils/discountProductFunctions";
import { discountCustomerFunctions } from "../utils/discountCustomerFunctions";
import { discountCustomerGroupFunctions } from "../utils/discountCustomerGroupFunctions";
import { DiscountManufacturerGroupLevel } from "../model/discount/DiscountManufacturerGroupLevel";
import { Manufacturer } from "../model/manufacturer/Manufacturer";
import { ManufacturerGroupLevelInfo } from "../model/manufacturer/ManufacturerGroupLevelInfo";
import { useProductService } from "../api/product/ProductService";
import { useManufacturerService } from "../api/manufacturer/ManufacturerService";
import { useLanguageStore } from "../stores/LanguageStore";
import { useProductHierarchyStore } from "../stores/ProductHierarchyStore";
import { storeToRefs } from "pinia";
import { ProductGroupNameByLanguage } from "../model/product/ProductGroupNameByLanguage";
import { NIL as emptyUuid } from "uuid";

const { t } = useI18n();
const route = useRoute();
const router = useRouter();
const val = useValidate();
const toast = useCumulusToast(useToast());
const { getDiscountById, updateDiscount, deleteDiscount } = useDiscountService();
const { getCustomersByIds, getCustomerGroupsByIds } = useDuplicatedDataService();
const { getProductsByIds } = useProductService();
const { getManufacturersByIds } = useManufacturerService();
const { getLanguageIso } = useLanguageStore();
const { getAllProductHierarchies } = useProductHierarchyStore();
const { languageIso } = storeToRefs(useLanguageStore());

const { productHierarchies } = storeToRefs(useProductHierarchyStore());
const discount = ref<Discount>(new Discount());
const initialDiscount = ref<Discount>(new Discount());
const loading = ref<boolean>(false);
const isSaving = ref<boolean>(false);
const isDeleting = ref<boolean>(false);
const previouslyFocusedInput = ref<HTMLInputElement | null>(null);
const unsavedChangesDialogVisible = ref(false);
const selectedProducts = ref<ProductWithDiscountInfo[]>([]);
const selectedCustomers = ref<SearchCustomer[]>([]);
const selectedCustomerGroups = ref<CustomerGroup[]>([]);
const selectedManufacturerGroupLevels = ref<ManufacturerGroupLevelInfo[]>([]);
const confirmedDiscard = ref(false);
const setFocusDiscountName = ref(true);

const {
  addProductToDiscountList,
  removeProductFromDiscount,
  updateDiscountPercentageOnProduct,
  updateFixedPriceOnProduct,
} = discountProductFunctions(discount, selectedProducts);

const { addCustomerToDiscount, removeCustomerFromDiscount } = discountCustomerFunctions(discount, selectedCustomers);

const { addCustomerGroupToDiscount, removeCustomerGroupFromDiscount } = discountCustomerGroupFunctions(
  discount,
  selectedCustomerGroups
);

const onDeleteDiscount = async () => {
  isDeleting.value = true;

  try {
    await deleteDiscount(discount.value.id);

    toast.add({
      severity: "success",
      summary: t("discount.deleted.summary"),
      detail: t("discount.deleted.detail", { name: discount.value.name }),
      closable: true,
    });

    routeToSearchPage();
  } finally {
    isDeleting.value = false;
  }
};

const onUpdateDiscount = async () => {
  val.value.$touch();
  await val.value.$validate();

  if (val.value.$error) {
    toast.add({
      severity: "warn",
      summary: t("validations.summary"),
      detail: t("validations.detail"),
      closable: true,
    });
    return;
  }

  try {
    isSaving.value = true;

    await updateDiscount(discount.value);

    toast.add({
      severity: "success",
      summary: t("discount.updated.summary"),
      detail: t("discount.updated.detail", { name: discount.value.name }),
      closable: true,
    });

    routeToSearchPage();
  } finally {
    isSaving.value = false;
  }
};

const routeToSearchPage = () => {
  confirmedDiscard.value = true;
  if (window.history.state.back === null) {
    router.push({ name: DiscountRoutes.Search, query: { search: "" } });
  } else {
    router.back();
  }
};

const updateDiscountRuleForAllProducts = (discountRule: DiscountRule) => {
  discount.value.products.forEach((product) => {
    product.discountRule = discountRule;
  });
  selectedProducts.value.forEach((product) => {
    product.discountRule = discountRule;
  });
};

const createDiscountedProductList = (
  discountProducts: DiscountProduct[],
  products: Product[]
): ProductWithDiscountInfo[] => {
  const productsWithDiscountInfo = [];

  for (const discountProduct of discountProducts) {
    for (const product of products) {
      if (discountProduct.id === product.id) {
        const discountProductList = ProductWithDiscountInfo.createFromProduct(
          product,
          discountProduct.discountRule,
          discountProduct.fixedPrice ?? 0,
          discountProduct.discountPercentage ?? 0
        );
        productsWithDiscountInfo.push(discountProductList);
      }
    }
  }

  return productsWithDiscountInfo;
};

const createDiscountedManufacturerList = (
  discountManufacturerGroupLevels: DiscountManufacturerGroupLevel[],
  manufacturers?: Manufacturer[]
): ManufacturerGroupLevelInfo[] => {
  return discountManufacturerGroupLevels.flatMap((dmh) => {
    const manufacturer = manufacturers?.find((manufacturer) => manufacturer.id === dmh.manufacturerId);
    const manufacturerName = manufacturer ? manufacturer.name : "";

    const manufacturerGroupLevelInfo = new ManufacturerGroupLevelInfo();
    manufacturerGroupLevelInfo.id = dmh.id;
    manufacturerGroupLevelInfo.manufacturerId = dmh.manufacturerId;
    manufacturerGroupLevelInfo.manufacturerName = manufacturerName;
    manufacturerGroupLevelInfo.discountPercentage = dmh.discountPercentage;

    const level1 = productHierarchies.value.find((hierarchy) => hierarchy.level1?.id === dmh.groupLevel1Id)?.level1;
    const level2 = productHierarchies.value.find((hierarchy) => hierarchy.level2?.id === dmh.groupLevel2Id)?.level2;
    const level3 = productHierarchies.value.find((hierarchy) => hierarchy.level3?.id === dmh.groupLevel3Id)?.level3;
    const level4 = productHierarchies.value.find((hierarchy) => hierarchy.level4?.id === dmh.groupLevel4Id)?.level4;
    const level5 = productHierarchies.value.find((hierarchy) => hierarchy.level5?.id === dmh.groupLevel5Id)?.level5;

    const group1 = level1
      ? ProductGroupNameByLanguage.createFromProductGroupName(level1, languageIso.value)
      : new ProductGroupNameByLanguage();
    const group2 = level2
      ? ProductGroupNameByLanguage.createFromProductGroupName(level2, languageIso.value)
      : new ProductGroupNameByLanguage();
    const group3 = level3
      ? ProductGroupNameByLanguage.createFromProductGroupName(level3, languageIso.value)
      : new ProductGroupNameByLanguage();
    const group4 = level4
      ? ProductGroupNameByLanguage.createFromProductGroupName(level4, languageIso.value)
      : new ProductGroupNameByLanguage();
    const group5 = level5
      ? ProductGroupNameByLanguage.createFromProductGroupName(level5, languageIso.value)
      : new ProductGroupNameByLanguage();

    manufacturerGroupLevelInfo.groupLevel1 = group1 ?? manufacturerGroupLevelInfo.groupLevel1;
    manufacturerGroupLevelInfo.groupLevel2 = group2 ?? manufacturerGroupLevelInfo.groupLevel2;
    manufacturerGroupLevelInfo.groupLevel3 = group3 ?? manufacturerGroupLevelInfo.groupLevel3;
    manufacturerGroupLevelInfo.groupLevel4 = group4 ?? manufacturerGroupLevelInfo.groupLevel4;
    manufacturerGroupLevelInfo.groupLevel5 = group5 ?? manufacturerGroupLevelInfo.groupLevel5;

    return [manufacturerGroupLevelInfo];
  });
};

const fetchProductsFromDiscount = async () => {
  const productIds = discount.value.products.map((x) => x.id);

  if (productIds.length === 0) {
    return [];
  }

  const products = await getProductsByIds(productIds);

  if (products.length === 0) {
    return [];
  }

  return createDiscountedProductList(discount.value.products, products);
};

const fetchCustomersFromDiscount = async () => {
  if (discount.value.customerIds.length === 0) {
    return [];
  }

  const customers = await getCustomersByIds(discount.value.customerIds);

  if (customers.length === 0) {
    return [];
  }

  return discount.value.customerIds.map((customerId) => {
    return customers.find((customer) => customer.id === customerId) as SearchCustomer;
  });
};

const fetchCustomerGroupsFromDiscount = async () => {
  if (discount.value.customerGroupIds.length === 0) {
    return [];
  }

  const customerGroups = await getCustomerGroupsByIds(discount.value.customerGroupIds);

  if (customerGroups.length === 0) {
    return [];
  }

  return discount.value.customerGroupIds.map((customerGroupId) => {
    return customerGroups.find((customerGroup) => customerGroup.id === customerGroupId) as CustomerGroup;
  });
};

const fetchManufacturersFromDiscount = async () => {
  const manufacturerIds = discount.value.manufacturerGroupLevels
    .map((x) => x.manufacturerId)
    .filter((x) => x !== emptyUuid);

  if (manufacturerIds.length === 0) {
    return createDiscountedManufacturerList(discount.value.manufacturerGroupLevels);
  }

  const manufacturers = await getManufacturersByIds(manufacturerIds);
  if (manufacturers.length === 0) {
    return [];
  }
  return createDiscountedManufacturerList(discount.value.manufacturerGroupLevels, manufacturers);
};

onMounted(async () => {
  document.addEventListener("keydown", handleKeydown);
  const discountId = route.params.id as string;
  loading.value = true;

  try {
    discount.value = await getDiscountById(discountId);
    await getLanguageIso();
    await getAllProductHierarchies();

    const [productsInfo, customersInfo, customerGroups, manufacturers] = await Promise.all([
      fetchProductsFromDiscount(),
      fetchCustomersFromDiscount(),
      fetchCustomerGroupsFromDiscount(),
      fetchManufacturersFromDiscount(),
    ]);

    if (productsInfo.length > 0) {
      selectedProducts.value = productsInfo;
    }

    if (customersInfo.length > 0) {
      selectedCustomers.value = customersInfo;
    }

    if (customerGroups.length > 0) {
      selectedCustomerGroups.value = customerGroups;
    }

    if (manufacturers.length > 0) {
      selectedManufacturerGroupLevels.value = manufacturers;
    }

    for (const product of selectedProducts.value) {
      if (product.discountRule === DiscountRule.FixedPrice) {
        updateFixedPriceOnProduct(product);
      }
      if (product.discountRule === DiscountRule.DiscountOnListPrice) {
        updateDiscountPercentageOnProduct(product);
      }
    }

    initialDiscount.value = cloneDeep(discount.value);
  } finally {
    loading.value = false;
  }
});

const hasUpdates = computed(() => {
  return !isEqual(discount.value, initialDiscount.value);
});

const onCancel = () => {
  previouslyFocusedInput.value = document.activeElement as HTMLInputElement;
  if (hasUpdates.value === true) {
    unsavedChangesDialogVisible.value = true;
  } else {
    routeToSearchPage();
  }
};

const stayOnPage = () => {
  unsavedChangesDialogVisible.value = false;
  if (previouslyFocusedInput.value) {
    previouslyFocusedInput.value.focus();
  }
};

const handleKeydown = (event: KeyboardEvent) => {
  if (event.key === "Escape") {
    if (unsavedChangesDialogVisible.value) {
      stayOnPage();
    } else onCancel();
  } else if (event.ctrlKey && event.key === "i") {
    if (unsavedChangesDialogVisible.value) {
      routeToSearchPage();
    }
  }
};

onBeforeUnmount(() => {
  document.removeEventListener("keydown", handleKeydown);
});

window.addEventListener("beforeunload", (e) => {
  if (hasUpdates.value) {
    e.preventDefault();
    e.returnValue = t("common.unsaved-changes-header");
  }
});

onBeforeRouteLeave((_to, _from, next) => {
  if (hasUpdates.value && !confirmedDiscard.value) {
    unsavedChangesDialogVisible.value = true;
    next(false);
  } else {
    next();
  }
});
</script>

<style scoped lang="scss">
.c-discount-margin {
  margin: var(--default-content-margin);
}

.c-spinner-container {
  position: relative;
  top: 175px;
}
.c-border-div {
  padding: 0.5rem;
  border-right: var(--footer-border);

  @media screen and (max-width: 992px) {
    border-right: none;
  }
}
:deep(.c-panel-content) {
  padding-top: 0;
}

.c-content-top {
  display: flex;
  flex-wrap: wrap;
}
.c-content-top-left {
  flex: 65%;
}
.c-content-top-right {
  flex: 35%;
}

.c-col-1 {
  flex: 50%;
}
.c-col-2 {
  flex: 50%;
}

@media (max-width: 1024px) {
  .c-content-top-left,
  .c-content-top-right {
    flex: 100%;
  }
}
</style>
